<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'

interface Props {
  open?: boolean
}

interface Emits {
  (e: 'close'): void
}

defineOptions({ inheritAttrs: false })
defineProps<Props>()
const emits = defineEmits<Emits>()

const onClose = () => {
  emits('close')
}
</script>

<template>
  <div
    class="fixed z-40 left-0 top-0 bg-gray-900 transform transition-opacity ease-in-out duration-300 h-full w-full"
    :class="open ? 'opacity-60' : 'opacity-0 collapse'"
  ></div>
  <div
    class="fixed z-40 left-0 top-0 flex transform transition-all ease-in-out duration-300 justify-center items-center w-full h-full"
    :class="open ? 'translate-y-0 opacity-100 overflow-y-auto' : 'overflow-y-initial translate-y-8 opacity-0 collapse'"
  >
    <div
      v-if="open"
      class="w-full max-h-lvh max-w-2xl bg-white shadow-lg"
      v-on-click-outside="() => onClose()"
      v-bind="$attrs"
    >
      <slot></slot>
    </div>
  </div>
</template>
