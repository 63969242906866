import type { UserCategory } from "@/api/models/definitions"

export interface Item {
  id: number
  label: string
  icon?: string
  active?: boolean
  items?: Item[]
}

export interface MotionTrack {
  id: number
  index: number
  status: number
}

export const MotionTrackDefault: MotionTrack = {
  id: 0,
  index: -1,
  status: -1,
}

export interface Message {
  text: string | undefined
  type: string | number | undefined
}

export interface EventItem {
  eventCode: string
  label: string
}

export interface UserCategoryItem {
  id: UserCategory
  label: string
}

export interface TabItem {
  label: string
  isDisabled?: boolean
}