export const toKebabCase = (text: string): string => {
  if (typeof text === 'string') {
    return text
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
  } else {
    return ''
  }
}

export const toNumber = (value: string): number | undefined => {
  if (value && value !== '0') {
    return parseInt(value)
  } else {
    return undefined
  }
}

export const splitArrayToNumber = (value: string): number[] => {
  if (value) {
    return value.split(',').map((s) => parseInt(s))
  } else {
    return []
  }
}

export const toBool = (value: string): boolean => {
  if (value && value === '1') {
    return true
  } else {
    return false
  }
}
