<script setup lang="ts">
import { computed, ref } from 'vue'
import { mdiLoading } from '@mdi/js'
import type { Validate } from '../interfaces/validate'
import SvgIcon from './SvgIcon.vue'

interface Props {
  title?: string
  text?: string
  icon?: string
  loading?: boolean
  disabled?: boolean
}

interface Emits {
  (e: 'click'): void
}

defineProps<Props>()
const emits = defineEmits<Emits>()
const errors = defineModel<{ [key: string]: Validate }>('errors')

const getError = computed((): Validate | undefined => {
  if (errors.value) {
    return Object.values(errors.value).find((error) => error.isInvalid)
  }
})

const validate = (): void => {
  if (errors.value) {
    const error = getError.value
    if (error) {
      error.isDirty = true
    } else {
      emits('click')
    }
  } else {
    emits('click')
  }
}
</script>

<template>
  <button
    type="button"
    class="group inline-flex items-center transition-all duration-200 border border-primary-dark text-primary-light bg-primary-dark p-2 enabled:cursor-pointer enabled:hover:shadow-inner disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-400"
    :title="title || text"
    :class="[
      getError?.isDirty
        ? 'enabled:text-white enabled:bg-error enabled:border-error enabled:hover:bg-error/80 enabled:hover:border-error active:animate-wiggle'
        : 'enabled:hover:text-secondary-light enabled:hover:bg-secondary-dark enabled:hover:border-secondary-dark',
    ]"
    @click="validate()"
    :disabled="loading || disabled"
    v-bind="$attrs"
  >
    <SvgIcon v-if="loading" :path="mdiLoading" class="animate-spin h-5 w-5" />
    <SvgIcon
      v-else
      :path="icon"
      class="h-5 w-5 group-disabled:fill-gray-400"
      :class="getError ? 'fill-white' : 'fill-primary-light'"
    />
    <span v-if="text" class="ms-1.5 me-2 text-sm text-nowrap">{{ text }}</span>
  </button>
</template>
