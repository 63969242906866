<script setup lang="ts">
import { mdiLoading, mdiPencil, mdiPencilOff, mdiWindowClose } from '@mdi/js'
import UnstyledButton from './UnstyledButton.vue'
import SvgIcon from './SvgIcon.vue'

interface Props {
  title: string
  edit?: boolean
  read?: boolean
  loading?: boolean
}

defineProps<Props>()
const model = defineModel<boolean>({ required: true })
</script>

<template>
  <div class="relative py-4 mx-4">
    <div class="inline-flex text-nowrap items-center absolute inset-0">
      <div class="font-medium text-lg text-gray-700 pr-2">{{ title }}</div>
      <div class="border-t border-gray-300 w-full"></div>
    </div>
    <div class="flex items-center justify-end relative">
      <slot></slot>
      <template v-if="edit">
        <UnstyledButton
          :title="read ? $t('common.edit') : $t('common.cancel')"
          class="border-l border-y border-gray-300 text-sm p-2 hover:bg-gray-100"
          :class="read ? 'bg-white' : 'shadow-inner bg-gray-100'"
          :disabled="loading"
          @click="model = !model"
        >
          <SvgIcon v-if="loading" :path="mdiLoading" class="animate-spin fill-gray-700 w-5 h-5" />
          <SvgIcon v-else :path="read ? mdiPencil : mdiPencilOff" class="fill-gray-700 w-5 h-5" />
        </UnstyledButton>
      </template>
      <UnstyledButton
        :title="$t('common.close')"
        class="peer bg-white border border-gray-300 text-sm p-2 enabled:hover:bg-gray-100"
        @click="model = false"
      >
        <SvgIcon :path="mdiWindowClose" class="w-5 h-5" />
      </UnstyledButton>
    </div>
  </div>
</template>
