import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import type {
  Client,
  Country,
  Profile,
  UserResponse,
  ClientDimension,
  OurboxesTargetUriSetup,
  UserClientSettingsSetupResponse,
  ServiceGroup,
  Carrier,
  Language,
  UserClientSettings,
  ZipZone,
  UserActionGroup,
  KPISetting,
  ViewFilter,
} from '@/api/models/types'
import { useUserApi } from '@/api/services/user'
import { ERROR, INFO, SUCCESS } from '@/constants'
import { useSelected } from '@/modules/motion/stores/selected'
import { useMotionStore } from '@/modules/motion/stores/motion'
import { useToastStore } from '@/shared/stores/toast'
import { useClientStore } from '@/modules/client/store/client'
import { useI18n } from 'vue-i18n'
import { ApplicationViewKey, Permission, ProfileActionHint, UserSettings } from '@/api/models/definitions'
import { useFilterStore } from '@/modules/filter/stores/filter'
import { toBool } from '@/shared/utils/format'
import { differenceInDays } from 'date-fns'
import { DefaultUserClientSettingsSetupResponse } from '@/api/models/defaults'
import router from '@/router'

export const useUserStore = defineStore('user', () => {
  const userApi = useUserApi()
  const toastStore = useToastStore()
  const selectedStore = useSelected()
  const filterStore = useFilterStore()
  const motionStore = useMotionStore()
  const clientStore = useClientStore()
  const clients = ref<Client[]>([])
  const countries = ref<Country[]>([])
  const languages = ref<Language[]>([])
  const profile = ref<Profile>()
  const userPermissions = ref<Permission[]>([])
  const userLimitations = ref<{ [item: number]: number[] }>({})
  const organization = ref<string>()
  const viewId = ref<number>()
  const views = ref<OurboxesTargetUriSetup[]>([])
  const dimensions = ref<ClientDimension[]>([])
  const serviceGroups = ref<ServiceGroup[]>([])
  const carriers = ref<Carrier[]>([])
  const clientSettings = ref<{ [item: string]: string }>({})
  const userSettings = ref<{ [item: string]: string }>({})
  const userActionGroups = ref<UserActionGroup[]>([])
  const kpiSetting = ref<KPISetting>()
  const isProfileUpdating = ref<boolean>(false)
  const isSettingsLoading = ref<boolean>(false)
  const isSettingsUpdating = ref<boolean>(false)
  const userRoleId = ref<number>()
  const userTypeId = ref<number>()
  const zipZones = ref<ZipZone[]>([])
  const { t } = useI18n()
  const { clientKey } = storeToRefs(clientStore)

  const setUserValue = (userResponse: UserResponse): void => {
    clients.value = userResponse.clients
    profile.value = userResponse.profile
    userPermissions.value = userResponse.userGeneralPermissions.permissions
    organization.value = userResponse.organization
    clientKey.value = userResponse.selectedClientKey
    userRoleId.value = userResponse.userRoleId
    userTypeId.value = userResponse.userTypeId
  }

  const setDefaultViewId = (userClientSettings: UserClientSettingsSetupResponse): void => {
    const defaultApplicationViewId = userClientSettings.userSettings[UserSettings.defaultApplicationViewId]
    if (defaultApplicationViewId && userClientSettings.clientKey !== DefaultUserClientSettingsSetupResponse.clientKey) {
      viewId.value = parseInt(defaultApplicationViewId)
      filterStore.setUserFilterSettings(userClientSettings.userViewFilters)
    }
    if (userClientSettings.clientKey !== DefaultUserClientSettingsSetupResponse.clientKey) {
      const check = views.value.find((v) => v.appViewId == viewId.value)
      if (!check) {
        viewId.value = views.value[0].appViewId
      }
    }
  }

  const setPageTitle = (): void => {
    const client = clients.value.find((client: Client) => client.id === clientKey.value)
    if (client) {
      document.title = `${client.name} - ${t('glossary.ourBox', 2)} 5`
    }
  }

  const setUserSettingValue = (userClientSettings: UserClientSettingsSetupResponse): void => {
    clientKey.value = userClientSettings.clientKey
    carriers.value = userClientSettings.carriers
    countries.value = userClientSettings.countries
    languages.value = userClientSettings.languages
    dimensions.value = userClientSettings.dimensions
    views.value = userClientSettings.webAppsEntryPoints
    serviceGroups.value = userClientSettings.serviceGroups
    userSettings.value = userClientSettings.userSettings
    clientSettings.value = userClientSettings.clientSettings
    zipZones.value = userClientSettings.zipZones
    userLimitations.value = userClientSettings.userLimitations
    userActionGroups.value = userClientSettings.userActionGroups
    kpiSetting.value = userClientSettings.kpiSetting
    setPageTitle()
    setDefaultViewId(userClientSettings)
  }

  const refreshUser = async (): Promise<boolean> => {
    const { data, error } = await userApi.getUser()
    if (error) {
      toastStore.showToast(ERROR, error.message)
      return false
    }
    if (!data) {
      return false
    }
    if (data.userProfileActionHint === ProfileActionHint.SystemMaintenanceActiveUserCanBypass) {
      toastStore.showToast(INFO, 'Bypass activated')
    }
    setUserValue(data)
    return true
  }

  const refreshUserSettings = async (clientId: string | undefined): Promise<boolean> => {
    isSettingsLoading.value = true
    setUserSettingValue(DefaultUserClientSettingsSetupResponse)
    const { data, error } = await userApi.getUserSettings(clientId)
    if (error || !data) {
      window.location.assign(`${import.meta.env.VITE_AUTH_BASE_URL}`)
      return false
    }
    selectedStore.resetAll()
    motionStore.resetAll()
    setUserSettingValue(data)
    if (router.currentRoute.value.name === 'callOff') {
      router.push('/')
    }
    isSettingsLoading.value = false
    return true
  }

  const updateProfile = async (editedData: Profile): Promise<void> => {
    isProfileUpdating.value = true
    const { error } = await userApi.updateProfile(editedData)
    if (error) {
      toastStore.showAlert(ERROR, error.message)
    } else {
      profile.value = { ...editedData }
      toastStore.showAlert(SUCCESS, t('message.savedSuccess'))
    }
    isProfileUpdating.value = false
  }

  const updateSettings = async (settings: UserClientSettings): Promise<void> => {
    isSettingsUpdating.value = true
    const { error } = await userApi.updateSettings(settings)
    if (error) {
      toastStore.showAlert(ERROR, error.message)
    } else {
      toastStore.showAlert(SUCCESS, t('message.savedSuccess'))
      userSettings.value.languageId = settings.languageId.toString() // TODO typed settings in a better manner
      // Do NOT alter viewId.value = settings.defaultApplicationViewId
    }
    isSettingsUpdating.value = false
  }

  const updateUserFilter = async (viewFilter: ViewFilter): Promise<void> => {
    const { data, error } = await userApi.updateUserFilter({
      dimension1Id: viewFilter.dimension1Id,
      dimension2Id: viewFilter.dimension2Id,
      dimension3Id: viewFilter.dimension3Id,
      carrierId: viewFilter.carrierId,
      countryId: viewFilter.countryId,
      zoneId: viewFilter.zipZoneId,
      serviceGroupId: viewFilter.serviceGroupId,
      status: viewFilter.status,
      selectNumberOfDays: differenceInDays(filterStore.dateRange.toViewDate, filterStore.dateRange.fromViewDate),
    })
    if (error) {
      toastStore.showToast(ERROR, t('message.filterSaveError'))
    }
    if (data) {
      toastStore.showToast(SUCCESS, t('message.filterSaveSuccess'))
    }
  }

  const checkClientSetting = (name: string): boolean => {
    if (name && name.length > 0 && clientSettings.value) {
      return toBool(clientSettings.value[name])
    } else {
      return false
    }
  }

  const checkAgentClientSetting = (name: string) => {
    if (viewId.value === ApplicationViewKey.Agent) {
      return checkClientSetting(name)
    } else {
      return true
    }
  }

  const hasPermission = (permission: Permission) => {
    if (!userPermissions.value) return false
    return userPermissions.value.find((p) => p == permission) != null
  }

  return {
    views,
    viewId,
    clients,
    profile,
    zipZones,
    carriers,
    countries,
    languages,
    dimensions,
    organization,
    userRoleId,
    userTypeId,
    clientSettings,
    userActionGroups,
    kpiSetting,
    userSettings,
    serviceGroups,
    isProfileUpdating,
    isSettingsLoading,
    isSettingsUpdating,
    refreshUser,
    refreshUserSettings,
    updateUserFilter,
    updateProfile,
    updateSettings,
    checkClientSetting,
    checkAgentClientSetting,
    hasPermission,
  }
})
