<script setup lang="ts">
import { computed, watch } from 'vue'
import { debounce } from 'lodash'
import { toKebabCase } from '../utils/format'
import { mdiClose } from '@mdi/js'
import { isEmail, isMaxLength, isRequired } from '../utils/validate'
import { isMatch } from 'date-fns'
import type { Validate } from '../interfaces/validate'
import UnstyledButton from './UnstyledButton.vue'
import SvgIcon from './SvgIcon.vue'
import { useThrottleFn } from '@vueuse/core'

interface Props {
  label: string
  icon?: string
  required?: boolean
  email?: boolean
  number?: boolean
  date?: boolean
  readonly?: boolean
  disabled?: boolean
  maxLength?: number
}

interface Emits {
  (e: 'clear'): void
}

defineOptions({ inheritAttrs: false })
const props = defineProps<Props>()
const emits = defineEmits<Emits>()
const model = defineModel<string>()
const error = defineModel<Validate>('error')
const name = toKebabCase(props.label)

const inValid = computed(() => {
  if (props.required && isRequired(model.value)) {
    return true
  } else if (props.maxLength && isMaxLength(model.value, props.maxLength)) {
    return true
  } else if (props.email && isEmail(model.value)) {
    return true
  } else if (props.date && model.value && !isMatch(model.value, 'P')) {
    return true
  } else {
    return false
  }
})

const onClear = () => {
  model.value = ''
  emits('clear')
}

watch(
  model,
  () => {
    error.value = {
      isInvalid: inValid.value,
      isDirty: false,
    }
  },
  { immediate: true },
)

watch(model, () => {
  if (!model.value) {
    onClear()
  }
})
</script>

<template>
  <div class="inline-flex items-center relative w-full">
    <SvgIcon
      v-if="icon"
      :path="icon"
      class="z-10 absolute h-5 w-5 ms-2"
      :class="model ? 'fill-gray-700' : 'fill-gray-600'"
    />
    <input
      autocomplete="off"
      type="text"
      spellcheck="false"
      :name="name"
      :title="label"
      v-model="model"
      placeholder=""
      class="peer block w-full text-sm border p-2 pe-8 outline-none transform transition-all duration-200 ease-linear motion-reduce:transition-none focus:ring-2 read-only:border-white read-only:focus:ring-0 read-only:text-black disabled:bg-white disabled:text-gray-400 disabled:border-b disabled:border-dotted disabled:border-gray-300"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      v-bind="$attrs"
      :class="[
        icon ? 'ps-8' : 'ps-2',
        error?.isDirty ? 'animate-shake border-error text-error ring-error' : 'focus:ring-gray-700 border-gray-300',
      ]"
    />
    <UnstyledButton
      v-if="model && !disabled && !readonly"
      :title="$t('common.clear')"
      class="absolute right-2"
      @click="onClear()"
    >
      <SvgIcon class="fill-gray-700 w-5 h-5" :path="mdiClose" />
    </UnstyledButton>
    <label
      :for="name"
      class="pointer-events-none text-sm absolute pt-2.5 peer-focus:left-1 top-0 mb-0 origin-[0_0] transform transition-all duration-200 ease-in-out motion-reduce:transition-none peer-disabled:text-gray-400"
      :class="[
        model ? '-translate-y-4 text-xs left-1 peer-read-only:text-xs' : icon ? 'left-8' : 'left-1',
        error?.isDirty ? 'text-error' : 'text-gray-600',
        readonly ? 'text-gray-600 text-sm' : 'peer-focus:-translate-y-4 peer-focus:text-xs ',
      ]"
    >
      <span class="bg-white px-1 truncate">
        {{ label }}
        <span v-if="required">*</span>
      </span>
    </label>
  </div>
</template>
