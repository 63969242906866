import { useApi } from '..'
import { useUserStore } from '@/modules/user/stores/user'
import type {
  UserClientSettingsSetupResponse,
  Profile,
  UserResponse,
  NoContentResponse,
  UserClientSettings,
  MySelectionOutbound,
  ClientErrorData,
} from '../models/types'

export const useUserApi = () => {
  const api = useApi()
  const user = useUserStore()
  const lobbyUrl = import.meta.env.VITE_LOBBY_BASE_URL
  const mainUrl = import.meta.env.VITE_MAIN_BASE_URL

  const getUser = async (): Promise<{ data: UserResponse | null; error: ClientErrorData | null }> =>
    await api.request.get<UserResponse>(lobbyUrl, 'user')

  const getUserSettings = async (
    clientId: string | undefined,
  ): Promise<{
    data: UserClientSettingsSetupResponse | null
    error: ClientErrorData | null
  }> => await api.request.get<UserClientSettingsSetupResponse>(lobbyUrl, `client/${clientId}/user/settings`)

  const updateProfile = async (
    payload: Profile,
  ): Promise<{ data: NoContentResponse | null; error: ClientErrorData | null }> =>
    await api.request.patch(mainUrl, 'profile', JSON.stringify(payload))

  const updateSettings = async (
    payload: UserClientSettings,
  ): Promise<{ data: NoContentResponse | null; error: ClientErrorData | null }> =>
    await api.request.patch(mainUrl, 'client/user/settings', JSON.stringify(payload))

  const updateUserFilter = async (
    payload: MySelectionOutbound,
  ): Promise<{ data: NoContentResponse | null; error: ClientErrorData | null }> =>
    await api.request.patch(mainUrl, `view/${user.viewId}/user/myselection`, JSON.stringify(payload))

  return {
    getUser,
    getUserSettings,
    updateProfile,
    updateSettings,
    updateUserFilter,
  }
}
