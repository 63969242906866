<script setup lang="ts">
interface Props {
  title: string
}

defineProps<Props>()
defineOptions({ inheritAttrs: false })
const open = defineModel<boolean>('open', { required: true })
</script>

<template>
  <div
    class="fixed z-50 left-0 top-0 bg-gray-900 transform transition-opacity ease-in-out duration-300 h-full w-full"
    :class="open ? 'opacity-60' : 'opacity-0 collapse'"
    key="item"
  ></div>
  <div
    class="fixed z-50 left-0 top-0 flex overflow-y-initial transform transition-all ease-in-out duration-300 justify-center items-center w-full h-screen"
    :class="open ? 'scale-1' : 'scale-75 opacity-0 collapse'"
  >
    <div v-if="open" class="w-full max-w-md bg-white shadow-lg" v-bind="$attrs">
      <div class="font-medium text-center text-xl text-gray-700 p-4">{{ title }}</div>
      <slot></slot>
    </div>
  </div>
</template>
